// import { Button } from "ui";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button,
  CircularProgress,
  Container,
  Heading,
  Image,
  VStack,
} from "@chakra-ui/react";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { BookLayout } from "../../layouts/BookLayout";
import { WithLayout } from "../../types/types";
import { useFormStore } from "../../utils/form-store";
import { useEffect, useState } from "react";

const Index: WithLayout<NextPage> = () => {
  const { clear } = useFormStore();
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);

  // Delay the ready signal of the page until the Hubspot cookie is set
  useEffect(() => {
    const i = setInterval(() => {
      if (document.cookie.includes("hubspotutk")) {
        clearInterval(i);
        setIsReady(true);
      }
    }, 100);
    const t = setTimeout(() => {
      clearInterval(i);
      setIsReady(true);
    }, 3000);
    return () => {
      clearInterval(i);
      clearTimeout(t);
    };
  }, []);

  function handleNext() {
    clear();
    router.push("/quote/suburbs");
  }

  return (
    <>
      <Image
        zIndex={-1}
        w="100%"
        h="100%"
        bg="red"
        position={"absolute"}
        inset={0}
        src={"/images/map.jpg"}
        objectFit={"cover"}
        alt="Map of Sydney"
        opacity={0.1}
      />
      <Container
        maxW="container.sm"
        as={VStack}
        bg="white"
        p={10}
        borderRadius="xl"
        borderWidth={2}
      >
        <Heading textAlign="center" size="3xl" pb={10}>
          Get a Quick Quote
        </Heading>
        {isReady ? (
          <Button
            onClick={handleNext}
            size="lg"
            rightIcon={<ChevronRightIcon />}
          >
            Get started
          </Button>
        ) : (
          <CircularProgress isIndeterminate color="black" />
        )}
      </Container>
    </>
  );
};

Index.getLayout = (page) => {
  return <BookLayout>{page}</BookLayout>;
};

export default Index;
