import { PhoneIcon, EmailIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Icon, IconButton, Link, Stack, Text } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import React from "react";
import { useSessionStorage } from "react-use";

type Props = {};

function _ContactBlurb({}: Props) {
  const [_isOpen, setIsOpen] = useSessionStorage<boolean>(
    "@holloway/popup/contact2",
    true
  );

  const isOpen = _isOpen;

  return (
    <Box
      display={{ base: "block", lg: isOpen ? undefined : "none" }}
      position={{ lg: isOpen ? "fixed" : "static" }}
      bottom={10}
      right={10}
    >
      <Stack
        bg="gray.800"
        borderWidth={{ lg: 2 }}
        borderRadius={{ lg: "lg" }}
        borderColor="orange.500"
        p="4"
        color="white"
        maxW={{ base: "100%", lg: "320px", xl: "375px" }}
        spacing="3"
      >
        <Stack spacing="0">
          <Stack direction="row">
            <Text fontWeight="bold" fontSize="lg" flex={1}>
              Don&apos;t like filling in forms?
            </Text>
            <IconButton
              display={{ base: "none", lg: "block" }}
              size="xs"
              aria-label="Close"
              icon={<Icon as={CloseIcon} />}
              onClick={() => setIsOpen(false)}
            />
          </Stack>
          <Text>
            The best way to book with us is to call one of our friendly team who
            can check availability and get your job booked immediately.
          </Text>
        </Stack>
        <Stack direction="row" align="center">
          <Icon as={PhoneIcon} color="accent.500" />
          <Link fontWeight="bold" as="a" href="tel:1300465569">
            1300 465 569
          </Link>
        </Stack>
        <Stack direction="row" align="center">
          <Icon as={EmailIcon} color="accent.500" />
          <Link
            fontWeight="bold"
            as="a"
            href="mailto:hello@hollowayremovals.com.au"
          >
            hello@hollowayremovals.com.au
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}

export const ContactBlurb = dynamic(async () => _ContactBlurb, { ssr: false });
