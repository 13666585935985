import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  IconButton,
  Image,
  Progress,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { ContactBlurb } from "../components/ContactBlurb";

type Props = {
  progress?: number;
};

export const BookLayout = ({
  progress,
  children,
}: PropsWithChildren<Props>) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const startNavigating = () => setIsNavigating(true);
    const stopNavigating = () => setIsNavigating(false);

    router.events.on("routeChangeStart", startNavigating);
    router.events.on("routeChangeComplete", stopNavigating);
    router.events.on("routeChangeError", stopNavigating);

    return () => {
      router.events.off("routeChangeStart", startNavigating);
      router.events.off("routeChangeComplete", stopNavigating);
      router.events.off("routeChangeError", stopNavigating);
    };
  }, [router, setIsNavigating]);

  function handleOnExit() {
    if (window.confirm("Are you sure?")) {
      router.push("https://hollowayremovals.com.au");
    }
  }

  return (
    <>
      <Box as="header" position="fixed" w="100%" top={0} zIndex={100}>
        <Box py={2} bg="gray.900">
          <Container maxW="container.lg" as={HStack}>
            <a href="https://hollowayremovals.com.au">
              <Image
                src={"/images/holloway-logo.png"}
                h={[6, 8, 10]}
                alt="Holloway Removals & Storage"
              />
            </a>
            <Box flex={1} />
            <Button
              as="a"
              href="tel:1300465569"
              fontSize={"xl"}
              fontWeight={700}
              leftIcon={<PhoneIcon fontSize={"sm"} />}
            >
              1300 465 569
            </Button>
            <IconButton
              aria-label="Exit"
              colorScheme="gray"
              color="white"
              variant="outline"
              fontSize="sm"
              icon={<CloseIcon />}
              onClick={handleOnExit}
            />
          </Container>
        </Box>
        <Progress
          isIndeterminate={isNavigating}
          id="progress-bar"
          key="progress"
          h="4px"
          color="accent"
          value={progress}
          bg="none"
        />
      </Box>
      <Container
        minH={{ lg: "100vh" }}
        maxW="container.lg"
        py={{ base: 32, lg: 20 }}
        as={Center}
      >
        {children}
      </Container>
      <ContactBlurb />
    </>
  );
};
